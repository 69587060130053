import { NotFoundContainer } from '../../containers/404'
import { PageContainer } from '../../components/layout'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { useRedirectOnAuthChanges } from '@dtx-company/inter-app/src/hooks/auth/useRedirectOnAuthChanges'

const NotFound = (): JSX.Element => {
  useRedirectOnAuthChanges()

  return (
    <PageContainer disableNotifications disableSideNav page={Routes.NOT_FOUND}>
      <NotFoundContainer />
    </PageContainer>
  )
}

export default NotFound
