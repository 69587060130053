import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'

export const NotFoundContainer: React.FC = () => {
  return (
    <Box display="block" padding={['40px 20px', '50px 30px', '50px']}>
      <Image
        src={'/static/images/page-not-found.png'}
        alt="page not found"
        width={['100%', '100%', 700]}
        margin="0 auto"
      />
      <Text
        variant={['body/medium', 'body/large', 'body/large']}
        textAlign="center"
        marginTop={[30, 35, 40]}
      >
        Oops! we can&apos;t seem to find that page.
      </Text>
    </Box>
  )
}
